import React from 'react';
//Mui
import { MuiThemeProvider } from '@material-ui/core/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import themeObject from './util/theme';
import jwtDecode from 'jwt-decode';
//redux
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/lib/integration/react";
import store, { persistor } from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserByToken } from './redux/actions/userActions';
import CustomRoute from 'customRoutes/CustomRoute';
import config from 'util/config';
import axios from 'axios';



const theme = createMuiTheme(themeObject);


// axios.defaults.baseURL = 'http://api.yakoafrica.local/enov'
axios.defaults.baseURL = 'https://api.yakoafricassur.com/enov'
// axios.defaults.baseURL = 'https://testapi.yakoafricassur.com/enov'
// axios.defaults.baseURL = 'https://yakoafricassur.com/devapp/public/enov'


const token = localStorage.getItem('FBIdToken');
axios.defaults.headers.common['Authorization'] = token;
// axios.defaults.headers.common['Cache-Control'] = 'no-cache';
if (token && token.replace('Bearer ', '') !== 'undefined') {
  const decodedToken = jwtDecode(token, config.secret, [config.algo]);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
 
    store.dispatch(getUserByToken());
  }
}


function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={theme}>
         
            <CustomRoute />
         
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}


export default App;
